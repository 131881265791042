import React from "react";
import { Text, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import i18next from "i18next";
import "./blockTextLeft.scss";

const BlockTextLeft = (props) => {
  const datasource = props.fields || {};
  if (
    datasource !== undefined &&
    Object.getOwnPropertyNames(datasource).length >= 1
  ) {
    return (
      <div
        className={
          "BlockTextLeft section pt-4 pb-4 bg-color " +
          props.fields.designVariant.value +
          ""
        }
      >
        <div className="container">
          <div className="call-to-action style-2 row">
            <div className="col-md-8 col-sm-12">
              <Text field={props.fields.Title} tag="p" className="title mb-0" />
              <p className="mb-0">
                <Text field={props.fields.Description} tag="i" />
              </p>
            </div>
            <div className="col-md-4 col-sm-12">
              <NavLinkAdv
                to={props.fields.Link && props.fields.Link.value.href}
                className="btn btn-custom-color btn-align-right btn-icon btn-icon-slide-in btn-icon-right mt-1"
              >
                <span>{props.fields.Link.value.title}</span>
                <i className="fa fa-long-arrow-right" />
              </NavLinkAdv>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t("no-datasource") +
          " " +
          props.rendering.componentName +
          " " +
          i18next.t("component")}
      </div>
    );
  }
};

export default withSitecoreContext()(BlockTextLeft);
