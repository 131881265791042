import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import InfiniteScroll from "react-infinite-scroll-component";
import ProductGridItem from '../ProductGridItem';
import ReactComponent from "../../ReactComponent";
import "./productsByCategoryScrollable.scss";
import { Query } from "react-apollo";
import {loader as gqlLoader} from "graphql.macro";
import { DotLoader } from 'react-spinners';

const ProductsByCategoryScrollableQuery = gqlLoader('./productsByCategoryScrollableQuery.graphql');

class ProductsByCategoryScrollable extends ReactComponent {

	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
      hasMore: false,
      selectedCategoryName: null,
      selectedCategoryId: (this.props.fields.productCategories[0].id).replace(/-/g, '').toLowerCase(),
      after: "0",
      nbOfItemsToShow: parseInt(this.props.fields.itemsToLoad.value)
    };
        this.updateCategorySelection = this.updateCategorySelection.bind(this);
        this.loadItems = this.loadItems.bind(this);
	}

	componentDidMount() {
        this.setState({
            selectedCategoryName: this.props.fields.productCategories[0].fields.Title.value
        });
    }

    updateCategorySelection(categoryId, categoryName) {
        this.setState({
            selectedCategoryName: categoryName,
            selectedCategoryId: categoryId.replace(/-/g, '').toLowerCase(),
            after: "0"
        });
    }

    loadItems() {
        this.setState({
            after: this.state.after + this.state.nbOfItemsToShow,
            hasMore: true
        });
    }

    render() {
        if (!this.props.fields.productCategories) return("No categories contributed");

        return(
            <Query query={ProductsByCategoryScrollableQuery} variables={{
              indexname: this.indexName,
              rootPath: "/sitecore/content/" + this.appName + "/",
              first: this.state.nbOfItemsToShow,
              after: this.state.after,
              categoryId: this.state.selectedCategoryId
            }}>
              {({loading, error, data}) => {
                if (loading)
                  return (
                      <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                          <DotLoader
                              sizeUnit={"px"}
                              size={25}
                              color={'#007FC1'}
                              loading={!this.state.isLoaded}
                          />
                      </div>
                  );

                if (error)
                  return (
                      <div>Error: {error.message}</div>
                  );

                let items = data.search.results.items;
                let totalItems = data.search.results.totalCount;

                return(
                  <div className="container commerce">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 main-wrap">
                        <div className="main-content">
                          <div data-layout="masonry" data-masonry-column="4" className="products-masonry masonry">
                            <div className="masonry-filter scrollableProductsFilters">
                              <div className="filter-action filter-action-center">
                                <ul data-filter-key="filter">
                                  {this.props.fields.productCategories &&
                                    this.props.fields.productCategories.map((cat, index) => (
                                      <li key={`filter-${index}`}>
                                        <a
                                          data-masonry-toogle={this.state.selectedCategoryName === cat.fields.Title.value ? "selected" : null}
                                          data-filter-value={"." + cat.fields.Title.value.toLowerCase()}
                                          href="#"
                                          className={this.state.selectedCategoryName === cat.fields.Title.value ? "selected" : null}
                                          onClick={() => this.updateCategorySelection(cat.id, cat.fields.Title.value)}
                                          >
                                          {cat.fields.Title.value}
                                        </a>
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            </div>
                            <div className="products-masonry-wrap">
                              <InfiniteScroll
                                dataLength={totalItems}
                                next={this.loadItems}
                                hasMore={this.state.hasMore}
                              >
                                <ul className="products masonry-products row masonry-wrap">
                                  {
                                      items.length !== 0 ?
                                          items.map((product, idx) => {
                                              return (
                                                <React.Fragment key={idx}>
                                                  <li
                                                    className={'product masonry-item col-md-3 col-sm-6'}
                                                    key={'infiniteScrollItems-' + this.state.selectedCategoryId + '-' + idx}>
                                                    <ProductGridItem index={idx} product={product} />
                                                  </li>
                                                </React.Fragment>
                                              );
                                          })
                                      :
                                          <p className={"text-center w-100"}>
                                              There is no item for this category.
                                          </p>
                                  }
                                </ul>
                              </InfiniteScroll>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Query>
        )
  }
}

export default withSitecoreContext()(ProductsByCategoryScrollable);
