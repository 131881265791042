import React from "react";
import {
  Text,
  withSitecoreContext,
  RichText,
  isExperienceEditorActive,
} from "@sitecore-jss/sitecore-jss-react";
import { Formik } from "formik";
import { ApolloConsumer, Query } from "react-apollo";
import { loader as gqlLoader } from "graphql.macro";
import "./ratingReviews.scss";
import StarRating from "../StarRating";
import RatingReviewsFilter from "../RatingReviewsFilter";
import ReactComponent from "../../ReactComponent";
import i18next from "i18next";
import { DotLoader } from "react-spinners";
import { handleSubmitReview } from "../Tagging";
import reviewIcon from "../../assets/images/icon-review.png";

const RATINGS_QUERY = gqlLoader("./ratingReviews.graphql");
const CommentsQuery = gqlLoader("./ratingReviewsComments.graphql");

class RatingReviews extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      status: "",
      rating: "",
      clickedStar: "",
      username: "",
      commentValue: "",
      itemdata: null,
      templateId: null,
      nbLoad: null,
    };
    this.onStarClick = this.onStarClick.bind(this);
    this.userNameChange = this.userNameChange.bind(this);
    this.commentChange = this.commentChange.bind(this);
    this.rankLines = this.rankLines.bind(this);
    this.updateData = this.updateData.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.activeRatingStatusId().then((res) => {
      if (res) {
        this.setState({
          templateId: res.data.search.results.items[0].item.id.toLowerCase(),
          nbLoad:
            this.props.fields.NbToLoad &&
            parseInt(this.props.fields.NbToLoad.value),
        });
      }
    });
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.sitecoreContext.route.name !==
      this.props.sitecoreContext.route.name
    ) {
      this.setState({
        itemdata: null,
        nbLoad: parseInt(this.props.fields.NbToLoad.value),
      });
    }
  }

  onStarClick(event) {
    let ratingValue = event.target.parentElement.getAttribute("data-rating");
    let clickedStarId = event.target.id;

    this.setState({
      rating: ratingValue,
      clickedStar: clickedStarId,
    });
  }

  userNameChange(event) {
    this.setState({ username: event.target.value });
  }

  commentChange(event) {
    this.setState({
      commentValue: event.target.value,
    });
  }

  updateData(data) {
    this.setState({
      itemdata: data,
    });
  }

  loadMore() {
    if (this.state.nbLoad !== null) {
      this.setState({
        nbLoad: this.state.nbLoad + parseInt(this.props.fields.NbToLoad.value),
      });
    }
  }

  rankLines(items) {
    let result = [];
    const data = items;

    // add stars in element
    for (let i = 0; i < 5; i++) {
      const stars = [];
      for (let j = 0; j < i + 1; j++) {
        let star = <i className="fa fa-heart" style={{ color: "#f97fb5" }}></i>;
        stars.push(star);
      }

      // number of comments by rates
      const nbOfComments = data.filter(
        (el) => el.item.rating.numberValue === i + 1
      ).length;

      // percentage for the bar
      const percentage = Math.floor((nbOfComments / data.length) * 100) + "%";

      // pushing lines
      result.push(
        <div key={i} className="line">
          <div className="starsContainer">{stars}</div>
          <div className="rankBarContainer">
            <div className="rankBar" style={{ width: percentage }} />
          </div>
          <span className={"nbReviewsLine"}>
            {nbOfComments} {i18next.t("Reviews")}
          </span>
        </div>
      );
    }

    return result.reverse();
  }

  render() {
    return (
      <React.Fragment>
        <div className="rating-container RandR">
          <div>
            <div className="separate" />
            <h2 className="title-review">
              <Text
                field={
                  this.props.fields && this.props.fields.CommentsSectionTitle
                }
              />
            </h2>
            <div id="reviews" className="Reviews">
              <div id="comments">
                <Query
                  query={CommentsQuery}
                  variables={{
                    rootPath:
                      "/sitecore/content/" +
                      this.appName +
                      "/Content/Rating and Reviews",
                    indexname: this.indexName,
                    productId: this.props.sitecoreContext.itemId
                      .replace(/-/g, "")
                      .toLowerCase(),
                    ratingstatusId:
                      this.state.templateId !== null
                        ? this.state.templateId
                        : "",
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <div
                          className="sweet-loading"
                          style={{ marginTop: "50px", marginBottom: "50px" }}
                        >
                          <DotLoader
                            sizeUnit={"px"}
                            size={50}
                            color={"#007FC1"}
                            loading={!this.state.isLoaded}
                          />
                        </div>
                      );

                    if (error) return <div>Error: {error.message}</div>;

                    let globalRank = [];
                    let items = data.search.results.items;

                    let dataToload;

                    if (this.state.itemdata === null) {
                      dataToload = data.search.results.items;
                    } else {
                      dataToload = this.state.itemdata;
                    }

                    if (items.length === 0)
                      return (
                        isExperienceEditorActive() && (
                          <div>{i18next.t("NoTimelineFoundLabel")}</div>
                        )
                      );
                    else if (items.length !== 0)
                      globalRank = this.globalRank(items);

                    const reducer = (accumulator, currentValue) =>
                      accumulator + currentValue;
                    let nbStars =
                      Math.round(
                        (globalRank.reduce(reducer) / globalRank.length) * 10
                      ) / 10;

                    return (
                      <React.Fragment>
                        <div className="reviewsSummary">
                          <div className="row">
                            <div className="globalScore col-md-3">
                              <div className="rank">
                                <span>
                                  {globalRank.length !== 0 ? nbStars : "0"} / 5
                                </span>
                              </div>
                              <StarRating value={nbStars} isLittleSvg={true} />{" "}
                              <span className={"nbReviews"}>
                                {globalRank.length} {i18next.t("Reviews")}{" "}
                              </span>
                            </div>
                            <div className="scoreRanking col-md-5">
                              <div className="linesContainer">
                                {this.rankLines(items)}
                              </div>
                            </div>
                            <div className="giveAdvice col-md-4">
                              <img src={reviewIcon} alt="" />
                              <p className={"experience"}>
                                {i18next.t("experience")}
                              </p>
                              <a className="btn" href="#review_form_wrapper">
                                {i18next.t("comment")}
                              </a>
                            </div>
                          </div>
                        </div>
                        <RatingReviewsFilter
                          onFilterChange={this.updateData}
                          comments={dataToload}
                        />
                        <ol className="commentlist">
                          {dataToload &&
                            (this.state.nbLoad
                              ? dataToload.slice(0, this.state.nbLoad)
                              : dataToload
                            ).map((commentItem, index) => (
                              <li
                                className="review even thread-even depth-1"
                                key={index}
                                id="li-comment-36"
                              >
                                <div
                                  id="comment-36"
                                  className="comment_container"
                                >
                                  <div className="comment-text">
                                    <StarRating
                                      value={
                                        commentItem.item.rating.numberValue
                                      }
                                      isLittleSvg={true}
                                    />
                                    <p className="meta">
                                      <span className="review__author">
                                        {commentItem.item.userName.value}{" "}
                                      </span>
                                      <time className="review__published-date">
                                        {commentItem.item.creationDate.value}
                                      </time>
                                    </p>
                                    <div className="description">
                                      <p>{commentItem.item.comment.value}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ol>
                        {
                          // this.state.nbLoad &&
                          // this.state.showButton &&
                          this.state.nbLoad < dataToload.length && (
                            <div className="col-12 text-center pb-5">
                              <button
                                className={"btn load-more"}
                                onClick={() => this.loadMore()}
                              >
                                {i18next.t("load-more")}
                              </button>
                            </div>
                          )
                        }
                      </React.Fragment>
                    );
                  }}
                </Query>
              </div>
              <div id="review_form_wrapper">
                <div id="review_form">
                  <div id="respond" className="comment-respond">
                    <span
                      id="reply-title"
                      className="comment-reply-title same__font"
                    >
                      <Text field={this.props.fields.RatingLabel} />
                    </span>
                    {this.state.show === false &&
                      this.state.status === "success" && (
                        <div className="alert alert-success" role="alert">
                          {i18next.t("thank-you")}
                        </div>
                      )}
                    {this.state.show && this.state.show === true && (
                      <ApolloConsumer>
                        {(client) => (
                          <Formik
                            enableReinitialize
                            initialValues={{
                              userName: this.state.username,
                              rating: this.state.rating,
                              comment: this.state.commentValue,
                              product: "",
                              privacy: false,
                            }}
                            onSubmit={async (
                              values,
                              { setErrors, setSubmitting }
                            ) => {
                              const { data } = await client.query({
                                query: RATINGS_QUERY,
                                variables: {
                                  userName: values.userName,
                                  rating: values.rating,
                                  comment: values.comment,
                                  product: this.props.sitecoreContext.itemId,
                                },
                              });
                              if (
                                data.ratingreviews ===
                                "Review Submitted Successfully"
                              ) {
                                handleSubmitReview(
                                  this.props.sitecoreContext.route.displayName,
                                  this.props.sitecoreContext.route.fields
                                    .codeEan.value
                                );
                                this.setState({
                                  show: false,
                                  status: "success",
                                });
                              } else {
                                setErrors({ submit: "Error sending review." });
                                setSubmitting(false);
                              }
                            }}
                            validate={(values) => {
                              let errors = {};
                              if (!values.privacy) {
                                errors.privacy = i18next.t("required");
                              }
                              if (!values.comment) {
                                errors.comment = i18next.t("required");
                              }

                              return errors;
                            }}
                          >
                            {(props) => {
                              const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleSubmit,
                              } = props;

                              return (
                                <React.Fragment>
                                  <div>
                                    <form
                                      id="commentform"
                                      className="comment-form another__font"
                                      onSubmit={handleSubmit}
                                    >
                                      <p className="comment-notes">
                                        <Text
                                          field={
                                            this.props.fields.RequiredFieldsInfo
                                          }
                                        />
                                      </p>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          name="userName"
                                          id="userName"
                                          size="40"
                                          className="form-control"
                                          placeholder={i18next.t("fullName")}
                                          value={this.state.username}
                                          onChange={this.userNameChange}
                                        />
                                      </div>
                                      <div className="comment-form-rating same__font">
                                        <label htmlFor="rating">
                                          {i18next.t("your-score")}
                                        </label>
                                        <p className="stars another__font">
                                          <span>
                                            <a
                                              className="star-1 same__font"
                                              data-rating="1"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  "rating-1" ===
                                                    this.state.clickedStar ||
                                                  1 <= this.state.rating
                                                    ? "fas fa-heart"
                                                    : "far fa-heart off"
                                                }
                                                id="rating-1"
                                              />
                                            </a>
                                            <a
                                              className="star-2 same__font"
                                              data-rating="2"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  "rating-2" ===
                                                    this.state.clickedStar ||
                                                  2 <= this.state.rating
                                                    ? "fas fa-heart"
                                                    : "far fa-heart off"
                                                }
                                                id="rating-2"
                                              />
                                            </a>
                                            <a
                                              className="star-3 same__font"
                                              data-rating="3"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  "rating-3" ===
                                                    this.state.clickedStar ||
                                                  3 <= this.state.rating
                                                    ? "fas fa-heart"
                                                    : "far fa-heart off"
                                                }
                                                id="rating-3"
                                              />
                                            </a>
                                            <a
                                              className="star-4 same__font"
                                              data-rating="4"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  "rating-4" ===
                                                    this.state.clickedStar ||
                                                  4 <= this.state.rating
                                                    ? "fas fa-heart"
                                                    : "far fa-heart off"
                                                }
                                                id="rating-4"
                                              />
                                            </a>
                                            <a
                                              className="star-5 same__font"
                                              data-rating="5"
                                              onClick={this.onStarClick}
                                            >
                                              <i
                                                className={
                                                  "rating-5" ===
                                                  this.state.clickedStar
                                                    ? "fas fa-heart"
                                                    : "far fa-heart off"
                                                }
                                                id="rating-5"
                                              />
                                            </a>
                                          </span>
                                        </p>
                                      </div>
                                      <p className="comment-form-comment another__font">
                                        <textarea
                                          id="comment"
                                          name="comment"
                                          cols="45"
                                          rows="8"
                                          placeholder={i18next.t("commentRate")}
                                          required=""
                                          value={this.state.commentValue}
                                          onChange={this.commentChange}
                                        />
                                        {errors.comment && touched.comment && (
                                          <div
                                            className="alert alert-danger"
                                            role="alert"
                                          >
                                            {errors.comment}
                                          </div>
                                        )}
                                      </p>
                                      <div className="pprivacy same__font">
                                        <input
                                          type="checkbox"
                                          id="privacy"
                                          name="privacy"
                                          value="privacy-key"
                                          checked={values.privacy === true}
                                          className="privacyBox"
                                          onChange={handleChange}
                                        />
                                        <RichText
                                          field={this.props.fields.Privacy}
                                        />
                                        {errors.privacy && touched.privacy && (
                                          <div
                                            className="alert alert-danger"
                                            role="alert"
                                          >
                                            {errors.privacy}
                                          </div>
                                        )}
                                      </div>
                                      <p className="form-submit another__font">
                                        <input
                                          name="submit"
                                          type="submit"
                                          id="submit"
                                          className="btn_custom_rr"
                                          value={i18next.t("submitComment")}
                                        />
                                        <input
                                          type="hidden"
                                          name="comment_post_ID"
                                          value="983"
                                          id="comment_post_ID"
                                        />
                                        <input
                                          type="hidden"
                                          name="comment_parent"
                                          id="comment_parent"
                                          value="0"
                                        />
                                      </p>
                                    </form>
                                  </div>
                                </React.Fragment>
                              );
                            }}
                          </Formik>
                        )}
                      </ApolloConsumer>
                    )}
                  </div>
                  <div className="row_custom_small Legal-form">
                    <RichText field={this.props.fields.LegaInformation} />
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSitecoreContext()(RatingReviews);
