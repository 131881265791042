import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import i18next from "i18next";

const ProductReviewsQuery = gqlLoader("./query.graphql");

class ProductReviews extends ReactComponent {
  state = {
    isLoaded: false,
    templateId: null,
  };

  componentDidMount() {
    this.activeRatingStatusId().then((res) => {
      this.setState({
        templateId: res.data.search.results.items[0].item.id.toLowerCase(),
      });
    });
  }

  render() {
    var productIdValue =
      this.props.productId !== ""
        ? this.props.productId.replace(/-/g, "").toLowerCase()
        : "";

    return (
      <Query
        query={ProductReviewsQuery}
        variables={{
          productId: productIdValue,
          indexName: this.indexName,
          rootPath:
            "/sitecore/content/" + this.appName + "/Content/Rating and Review",
          ratingstatusId: this.state.templateId ? this.state.templateId : "",
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div></div>;
          if (error) return <div>Error: {error.message}</div>;
          return (
            <>
              {data && data.ratingcount.results && (
                <>
                  <span>
                    ({data.ratingcount.results.totalCount}{" "}
                    {i18next.t("Reviews")}){" "}
                  </span>
                  <input
                    id="reviews_number"
                    type="hidden"
                    value={data.ratingcount.results.totalCount}
                  ></input>
                </>
              )}
            </>
          );
        }}
      </Query>
    );
  }
}
export default withSitecoreContext()(ProductReviews);
