import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Query } from "react-apollo";
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import NavLinkAdv from "../../utils/NavLinkAdv";
import ProductPush from "../../utils/Common/ProductPush/index";
import './HeaderMegaMenu.scss';
import loupeimage from '../../assets/images/icon-loupe.svg';
import { handleDiagPeauClick, handleNavigationClick, handleLogoClick } from '../Tagging';

const HeaderMegaMenuQuery = gqlLoader('./HeaderMegaMenu.graphql');
const BREAKPOINT = 1024;

class HeaderMegaMenu extends ReactComponent {
  state = {
    isMobile: false,
  };

  componentDidMount() {
    this.handleIfMobile();
    window.addEventListener('resize', () => {
      this.handleIfMobile();
    });
  }

  componentDidUpdate(prevProps) {
    // Close mobile menu on page change
    if (prevProps.sitecoreContext.route.displayName !== this.props.sitecoreContext.route.displayName && this.state.isMobile) {
      this.handleCloseMobile();
    }
  }

  handleOpen(e) {
    if (e.currentTarget.querySelector('.secondLevel')) {
      document.querySelectorAll('.firstLevel').forEach((el) => el.classList.remove('active'));
      e.currentTarget.classList.add('active');
      document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
      e.currentTarget.querySelector('.secondLevel').classList.remove('closed');
    } else {
      document.querySelectorAll('.firstLevel').forEach((el) => el.classList.remove('active'));
      document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
    }
  }

  handleClose() {
    document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
    document.querySelectorAll('.firstLevel').forEach((el) => el.classList.remove('active'));
  }

  // Mobile code

  handleIfMobile() {
    if (window.innerWidth < BREAKPOINT) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  handleToggleMobile(e) {
    document.querySelector('.navIcon').classList.toggle('open');
    document.querySelector('.navContainerMobile').classList.toggle('open');
    document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
    e.stopPropagation();
  }

  handleCloseMobile() {
    document.querySelector('.navIcon').classList.remove('open');
    document.querySelector('.navContainerMobile').classList.remove('open');
    document.querySelectorAll('.secondLevel').forEach((el) => el.classList.add('closed'));
  }

  handleToggleUnfold(e) {
    e.currentTarget.classList.toggle('folded');
    e.currentTarget.querySelector('.linksList').classList.toggle('folded');
    e.stopPropagation();
  }

  handleBackToFirstLevel(e) {
    e.currentTarget.parentElement.parentElement.classList.add('closed');
    e.stopPropagation();
  }

  render() {
    return (
      <div className="HeaderMegaMenu">
        <Query query={HeaderMegaMenuQuery} variables={{ indexName: this.indexName, rootPath: 'sitecore/content/' + this.appName + '/' }}>
          {({ loading, error, data }) => {
            if (loading) return <p>... Chargement ...</p>;
            if (error) return <p>Error: {error.message}</p>;
            else {
              const menuData = data.headerMegaMenu.results.items[0];

              //DESKTOP
              if (!this.state.isMobile) {
                return (
                  <nav className="desktop">
                    <div className="wrapLeft">
                      <div className="logoContainer">
                        {' '}
                        {/* left part with logo */}
                        <NavLinkAdv to="/" onClick={() => handleLogoClick()}>
                          <Image loading="lazy" field={menuData && menuData.item.logo} />
                        </NavLinkAdv>
                      </div>
                      <div className="navContainer" onMouseLeave={this.handleClose}>
                        {' '}
                        {/* Central part with nav */}
                        <ul>
                          {menuData.item.children &&
                            menuData.item.children.map((menuItem, index) => {
                              return (
                                <li
                                  tabIndex="0"
                                  className="firstLevel"
                                  key={index}
                                  onMouseOver={(e) => this.handleOpen(e)}
                                  onFocus={(e) => this.handleOpen(e)}>
                                  {menuItem.children.length !== 0 ? (
                                    <Text className="span" tag="span" field={menuItem.categoryLabel} />
                                  ) : (
                                    <NavLinkAdv
                                      onClick={() => handleNavigationClick('header', menuItem.categoryLabel)}
                                      to={menuItem.categoryLink.url}>
                                      <Text className="span" tag="span" field={menuItem.categoryLabel} />
                                    </NavLinkAdv>
                                  )}
                                  {/* ouverture megamenu */}
                                  {menuItem.children.length !== 0 && (
                                    <div className="closed secondLevel">
                                      {/** The product push */}
                                      {menuItem.productPush.targetItem && (
                                        <span onClick={this.handleClose}>
                                          <ProductPush
                                            image={menuItem.productPush.targetItem && menuItem.productPush.targetItem.image}
                                            title={menuItem.productPush.targetItem && menuItem.productPush.targetItem.title}
                                            url={menuItem.productPush.targetItem && menuItem.productPush.targetItem.url}
                                            label={menuItem.discoverLabel && menuItem.discoverLabel.value}
                                            codeEan={menuItem.productPush.targetItem.eanCode}
                                          />
                                        </span>
                                      )}

                                      {menuItem.children &&
                                        menuItem.children.map((itemColumn, index) => (
                                          <div className={index >= 1 ? 'withBorder itemColumns' : 'itemColumns'} key={index}>
                                            <Text tag="h4" field={itemColumn.categoryFilterLabel} />
                                            {itemColumn.children.map((itemLink, index) => (
                                              <div key={index} className="linkContainer" onClick={this.handleClose}>
                                                <NavLinkAdv
                                                  onClick={() =>
                                                    handleNavigationClick(
                                                      'header',
                                                      menuItem.categoryLabel.value + '::' + itemLink.subCategoryLabel.value
                                                    )
                                                  }
                                                  to={itemLink.subCategoryLink.url}>
                                                  {itemLink.subCategoryLabel.value}
                                                </NavLinkAdv>
                                              </div>
                                            ))}
                                            {index === 0 && (
                                              // button of the 1rst column
                                              <span className="btn-ctn" onClick={this.handleClose}>
                                                <NavLinkAdv className="btn" to={menuItem.categoryLink.url}>
                                                  {menuItem.categoryButtonLabel.value}
                                                </NavLinkAdv>
                                              </span>
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="wrapRight">
                      {' '}
                      {/* Right part with button & search icon */}
                      <NavLinkAdv to={menuData.item && menuData.item.buttonLink.url} className="btn diagBtn">
                        {menuData.item && menuData.item.buttonLabel.value}
                      </NavLinkAdv>
                      <NavLinkAdv to={menuData && menuData.item.logoLink.url}>
                        <img src={loupeimage} alt="rechercher" width="70px" height="70px" />
                      </NavLinkAdv>
                    </div>
                  </nav>
                );
              }

              // MOBILE
              if (this.state.isMobile) {
                return (
                  <nav className="mobile">
                    {/* burger icon */}
                    <div className="head">
                      <div className="wrapLeft">
                        <div className="navIcon" onClick={this.handleToggleMobile}>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      <div className="logoContainer">
                        {' '}
                        {/* center part with logo */}
                        <NavLinkAdv to="/" onClick={() => handleLogoClick()}>
                          <Image loading="lazy" field={menuData && menuData.item.logo} />
                        </NavLinkAdv>
                      </div>
                      <div className="wrapRight">
                        {' '}
                        {/* Right part with search icon */}
                        <NavLinkAdv to={menuData && menuData.item.logoLink.url}>
                          <img
                            onClick={(e) => this.handleToggleMobile(e)}
                            src={loupeimage}
                            alt="rechercher"
                            style={{ marginLeft: 20 }}
                            width="30px"
                            height="30px"
                          />
                        </NavLinkAdv>
                      </div>
                    </div>
                    <div className="navContainerMobile">
                      {' '}
                      {/* Nav mobile */}
                      <ul>
                        {menuData.item.children &&
                          menuData.item.children.map((menuItem, index) => {
                            return (
                              <li className="firstLevel" key={index} onClick={(e) => this.handleOpen(e)}>
                                {menuItem.children.length !== 0 ? (
                                  <Text field={menuItem.categoryLabel} />
                                ) : (
                                  <NavLinkAdv
                                    onClick={() => handleNavigationClick('header', menuItem.categoryLabel.value)}
                                    to={menuItem.categoryLink.url}>
                                    <Text field={menuItem.categoryLabel} />
                                  </NavLinkAdv>
                                )}
                                {/* ouverture megamenu */}
                                {menuItem.children.length !== 0 && (
                                  <div className="closed secondLevel">
                                    {/** The product push */}
                                    <div className="headerSecondLevel">
                                      <div className="iconBack" onClick={(e) => this.handleBackToFirstLevel(e)}></div>
                                      <p>
                                        <Text field={menuItem.categoryLabel} />
                                      </p>
                                      <div className="iconClose" onClick={(e) => this.handleToggleMobile(e)}></div>
                                    </div>
                                    {menuItem.children &&
                                      menuItem.children.map((itemColumn, index) => (
                                        <div className="itemColumnsMobile folded" key={index} onClick={(e) => this.handleToggleUnfold(e)}>
                                          <Text tag="h4" field={itemColumn.categoryFilterLabel} />
                                          <div className="linksList folded">
                                            {itemColumn.children.map((itemLink, index) => (
                                              <div key={index} className="linkContainer">
                                                <NavLinkAdv
                                                  onClick={() =>
                                                    handleNavigationClick(
                                                      'header',
                                                      menuItem.categoryLabel.value + '::' + itemLink.subCategoryLabel.value
                                                    )
                                                  }
                                                  to={itemLink.subCategoryLink.url}>
                                                  {itemLink.subCategoryLabel.value}
                                                </NavLinkAdv>
                                              </div>
                                            ))}
                                            {index === 0 && (
                                              // button of the 1rst column
                                              <span>
                                                <NavLinkAdv className="btn" to={menuItem.categoryLink.url}>
                                                  {menuItem.categoryButtonLabel.value}
                                                </NavLinkAdv>
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    <span>
                                      <ProductPush
                                        image={menuItem.productPush.targetItem && menuItem.productPush.targetItem.image}
                                        title={menuItem.productPush.targetItem && menuItem.productPush.targetItem.title}
                                        url={menuItem.productPush.targetItem && menuItem.productPush.targetItem.url}
                                        label={menuItem.discoverLabel && menuItem.discoverLabel.value}
                                        codeEan={menuItem.productPush.targetItem.eanCode}
                                      />
                                    </span>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                      <span onClick={(e) => this.handleToggleMobile(e)}>
                        <NavLinkAdv className="btn diagBtn" to={menuData.item && menuData.item.buttonLink.url}>
                          {menuData.item && menuData.item.buttonLabel.value}
                        </NavLinkAdv>
                      </span>
                    </div>
                  </nav>
                );
              }
            }
          }}
        </Query>
      </div>
    );
  }
}
export default withSitecoreContext()(HeaderMegaMenu);
