import React from "react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import i18next from "i18next";

class CartMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: "",
      productName: "",
      cartmsgclass: "",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("cartItems")) {
      let sessionItems = sessionStorage.getItem("cartItems");
      let getSessionItems =
        sessionItems !== null ? JSON.parse(sessionItems) : "";

      if (getSessionItems !== "") {
        this.setState({
          cartItems: getSessionItems,
        });

        let idx = getSessionItems.findIndex(
          (i) => i.id == this.props.productid
        );
        if (idx >= 0) {
          this.setState({
            productName: getSessionItems[idx].productname.value,
          });
        }
      }
    }
    if (sessionStorage.getItem("AddToCart")) {
      let sessionMessage = sessionStorage.getItem("AddToCart");
      let getSessionMessage = sessionMessage !== null ? sessionMessage : "";

      if (getSessionMessage !== "") {
        this.setState({
          cartmsgclass: getSessionMessage,
        });
        sessionStorage.removeItem("AddToCart");
      }
    }
  }

  render() {
    //const designVariant = typeof this.props.fields.designVariant === 'undefined' ? "" : this.props.fields.designVariant.value;

    let contentText = `“${this.state.productName}” has been added to your cart.`;
    return (
      <div className={`woocommerce-message ${this.state.cartmsgclass}`}>
        <NavLinkAdv to="/cart" className="button wc-forward">
          {i18next.t("view-cart")}
        </NavLinkAdv>{" "}
        {contentText}
      </div>
    );
  }
}

export default CartMessage;
