import React from "react";
import {
  Text,
  Image,
  withSitecoreContext,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import StarRating from "../StarRating";
import { DotLoader } from "react-spinners";
import "./productBannerGallery.scss";
import i18next from "i18next";
import ProductReviews from "../../utils/ProductReviews";

class ProductBannerGallery extends React.Component {
  state = {
    images: null,
    activeImage: this.props.sitecoreContext.route.fields.image1,
    isLoaded: false,
  };

  componentDidMount() {
    const images = this.getImagesArray();
    const activeImage = images[0];

    this.setState({
      activeImage: activeImage,
      images: images,
      isLoaded: true,
    });
  }

  getImagesArray() {
    const product = this.props.sitecoreContext.route.fields;

    const images = [];
    const imageItems = Object.keys(product).filter((key) =>
      key.includes("image")
    );
    imageItems.forEach(function (key) {
      if (product[key]) {
        images.push(product[key]);
      }
    });
    return images;
  }

  handleClick(index) {
    this.setState({ activeImage: this.state.images[index] });
  }

  render() {
    const { isLoaded, activeImage, images } = this.state;
    const product = this.props.sitecoreContext.route.fields;
    const productId =
      this.props.sitecoreContext.itemId !== ""
        ? this.props.sitecoreContext.itemId.replace(/-/g, "").toLowerCase()
        : "";

    if (!isLoaded) {
      return (
        <div
          className="sweet-loading"
          style={{ marginTop: "50px", marginBottom: "50px" }}
        >
          <DotLoader
            sizeUnit={"px"}
            size={50}
            color={"#007FC1"}
            loading={!isLoaded}
          />
        </div>
      );
    } else {
      return (
        <div className="ProductBannerGallery commerce">
          <div
            className="imgContainer"
            style={{ backgroundImage: "url(" + activeImage.value.src + ")" }}
          ></div>

          <div className="thumbnailsContainer">
            {images.map((img, index) => {
              return (
                <div
                  onClick={() => this.handleClick(index)}
                  className={`thumb ${
                    activeImage === images[index] ? "active" : ""
                  }`}
                  key={index}
                  style={{ backgroundImage: "url(" + img.value.src + ")" }}
                ></div>
              );
            })}
          </div>

          <div className="textContainer">
            <h1>
              <Text field={product && product.title} />
            </h1>
            <div className="text">
              <RichText field={product && product.productShortDescription} />
            </div>
            {product.rating &&
              product.rating.value != null &&
              product.rating.value != 0 && (
                <div>
                  <StarRating
                    value={parseInt(product.rating.value)}
                    isLittleSvg={true}
                  />{" "}
                  <ProductReviews productId={productId} />
                </div>
              )}
            {product.buttonUrl &&
              product.buttonUrl.value != null &&
              product.buttonUrl.value != 0 && (
                <a
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={product.buttonUrl.value}
                >
                  {i18next.t("acheter")}
                </a>
              )}
          </div>
        </div>
      );
    }
  }
}

export default withSitecoreContext()(ProductBannerGallery);
