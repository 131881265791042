import React from "react";
import {
  Text,
  RichText,
  withSitecoreContext,
  isExperienceEditorActive,
} from "@sitecore-jss/sitecore-jss-react";
import { DotLoader } from "react-spinners";
import i18next from "i18next";
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import "./timeline.scss";

const TimelineQuery = gqlLoader("./query.graphql");

class Timeline extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      windowWidth: "",
    };
  }

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    function SortByYear(x, y) {
      if (y.item.eventYear && y.item.eventYear.numberValue !== undefined) {
        return x.item.eventYear.numberValue === y.item.eventYear.numberValue
          ? 0
          : x.item.eventYear.numberValue < y.item.eventYear.numberValue
          ? 1
          : -1;
      }
    }

    return (
      <Query
        query={TimelineQuery}
        variables={{
          rootpath: "/sitecore/content/" + this.appName + "/",
          indexname: this.indexName,
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div
                className="sweet-loading"
                style={{ marginTop: "50px", marginBottom: "50px" }}
              >
                <DotLoader
                  sizeUnit={"px"}
                  size={50}
                  color={"#8986ca"}
                  loading={!this.state.isLoaded}
                />
              </div>
            );
          if (error)
            return <div>{i18next.t("error") + ": " + error.message}</div>;
          else if (data.search.results.items.length === 0)
            return (
              isExperienceEditorActive() && (
                <div>{i18next.t("NoTimelineFoundLabel")}</div>
              )
            );
          return (
            <div id="history">
              <Text
                field={this.props.fields && this.props.fields.Title}
                tag={"h2"}
                className={"title"}
              />
              <div className="history">
                <div className="dashedBorder" />
                {data.search.results &&
                  data.search.results.items
                    .sort(SortByYear)
                    .map(function (event, idx) {
                      if (idx % 2 === 0) {
                        return (
                          <div
                            className="row_custom_small item-right"
                            key={idx}
                            data-type={idx}
                          >
                            <div className="item">
                              <div className="dashedBorderLittle" />
                              <div className="content">
                                <Text
                                  field={event.item && event.item.eventYear}
                                  tag={"span"}
                                  className={"date"}
                                />
                                {/*<Text field={event.item.eventTitle} tag={"h2"} className={"eventTitle"}/>*/}
                                <RichText
                                  field={event.item.eventContent}
                                  className={"richContent"}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="row_custom_small item-left"
                            key={idx}
                            data-type={idx}
                          >
                            <div className="item">
                              <div className="dashedBorderLittle left" />
                              <div className="content">
                                <Text
                                  field={event.item && event.item.eventYear}
                                  tag={"span"}
                                  className={"date"}
                                />
                                {/*<Text field={event.item.eventTitle} tag={"h2"} className={"eventTitle"}/>*/}
                                <RichText
                                  field={event.item.eventContent}
                                  className={"richContent"}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withSitecoreContext()(Timeline);
