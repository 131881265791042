import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import i18next from "i18next";
import "../../assets/scss/global/header.scss";
import "./HeaderCover.scss";

class HeaderCover extends React.Component {
  componentDidMount() {
    if (!this.props.fields.Layout2.value) {
      document
        .querySelector("#close-top")
        .addEventListener("click", function () {
          document.querySelector("body").classList.add("show-content");
        });

      document
        .querySelector("#open-top")
        .addEventListener("click", function () {
          document.querySelector("body").classList.remove("show-content");
        });
    }
  }

  render() {
    const designVariant =
      typeof this.props.fields.designVariant === "undefined"
        ? ""
        : this.props.fields.designVariant.value;

    if (this.props.fields.Layout2.value) {
      // Layout with text under image on mobile
      return (
        <div className={"header__cover layout2 " + designVariant}>
          <div
            className="img-ctn"
            style={{
              backgroundImage:
                "url(" + this.props.fields.HeaderCoverImage.value.src + ")",
            }}
          ></div>
          <div className="container">
            <div className="header__title">
              {this.props.fields.HeaderLogoBig.value.src && (
                <div className="header__title-logo">
                  <Image
                    loading="lazy"
                    field={this.props.fields.HeaderLogoBig}
                  />
                </div>
              )}
              <Text
                field={this.props.fields.Title}
                tag="div"
                className="header__title-title"
              />
              <Text
                field={this.props.fields.SubTitle}
                tag="div"
                className="header__title-subtitle"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={designVariant}>
          <div
            className="header__cover"
            style={{
              backgroundImage:
                "url(" + this.props.fields.HeaderCoverImage.value.src + ")",
            }}
          >
            <button
              id="open-top"
              className="de-button not-btn"
              title={i18next.t("show-more")}
            />
            <button
              id="close-top"
              className="de-button not-btn"
              title={i18next.t("show-less")}
            />
            <div className="header__title">
              <a href="#">
                <div className="header__title-logo">
                  <Image
                    loading="lazy"
                    field={this.props.fields.HeaderLogoBig}
                  />
                </div>
                <Text
                  field={this.props.fields.Title}
                  tag="div"
                  className="header__title-title"
                />
                <Text
                  field={this.props.fields.SubTitle}
                  tag="div"
                  className="header__title-subtitle"
                />
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default HeaderCover;
